/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/

/* css tweaking */
select.form-control {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cursor-default {
  cursor: default !important;
}

.btn.disabled {
  cursor: default;
}

/* Custom switch right aligned */
.custom-control.custom-switch.switch-right {
  padding-left: 0;
}

.switch-right > label.custom-control-label {
  position: relative;
  padding-right: 3rem;
}

.switch-right > label.custom-control-label::before,
.switch-right > label.custom-control-label::after {
  right: 0;
  left: auto;
}

.custom-switch.switch-right .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(-1.3rem);
  transform: translateX(-1.3rem);
}

.card-border-left {
  position: relative;
}

.card-border-left::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left-width: 2px;
  border-right-width: calc(0.375rem - 2px);
  border-left-style: solid;
  border-right-style: solid;
  border-left-color: inherit;
  border-right-color: transparent;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.card-border-left-lg::after {
  border-left-width: 3px;
  border-right-width: calc(0.375rem - 3px);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--light);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--light);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--gray);
}

.btn-xs {
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem;
}

.btn-rounded-circle {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  border-radius: 50rem;
}

.btn-rounded-circle.btn-lg {
  padding-left: 1.122rem;
  padding-right: 1.122rem;
}

.btn-rounded-circle.btn-sm {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}

.btn-rounded-circle.btn-xs {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.mnw-100 {
  min-width: 100% !important;
}

.mnw-50 {
  min-width: 50% !important;
}

.mnw-25 {
  min-width: 25% !important;
}

.mnh-100 {
  min-height: 100% !important;
}

.mnh-50 {
  min-height: 50% !important;
}

.mnh-25 {
  min-height: 25% !important;
}

.alert-warning-soft {
  background-color: rgba(250, 215, 118, 0.1) !important;
}
